




























import { Prop, Component, Vue, Watch } from 'vue-property-decorator'
import Note from '@/components/views/components/Note.vue'

@Component({
  components: { Note },
  name: 'NoteSet'
})
export default class NoteSet extends (Vue) {
  @Prop({ default: () => [] }) notes!: any[]

  private notesToDisplay: any[] = []

  mounted () {
    this.notesToDisplay = this.notes
  }

  @Watch('notes')
  onNotesChanged () {
    console.log('saw change')
    this.notesToDisplay = this.notes
  }
}
