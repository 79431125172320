

















































import { ISO8601Date, Now } from '@/edshed-common/api'
import { Prop, Component, Vue, Watch } from 'vue-property-decorator'
import AvatarView from '@/edshed-common/avatar/AvatarView.vue'

@Component({
  components: { AvatarView },
  name: 'Note'
})
export default class Note extends Vue {
  @Prop({ default: null }) noteId! : number | null
  @Prop({ default: '' }) message! : string
  @Prop({ required: true }) userId! : number
  @Prop({ default: Now() }) date! : ISO8601Date
  @Prop({ default: '{}' }) avatar! : string
  @Prop({ default: '', required: true }) username! : string
  @Prop({ default: false }) editable! : boolean

  editMessage: string = ''

  isEdit = false

  mounted () {
    this.editMessage = this.message
    this.isEdit = this.editable
  }

  @Watch('editable')
  onEditableChanged () {
    this.isEdit = this.editable
  }

  @Watch('message')
  onMessageChanged () {
    this.editMessage = this.message
  }

  cancel () {
    if (this.noteId) {
      this.editMessage = this.message
      this.isEdit = false
    } else {
      this.$emit('cancel')
    }
  }

  edit () {
    this.$emit('edit', { note: this.editMessage, id: this.noteId })
    this.isEdit = false
  }
}
